import React from 'react';
import classnames from 'classnames';

import classes from './WorkTypeTag.module.scss';

const WorkTypeTag = ({ tag, className, variant }) => {
  const genre = tag?.name;
  const type = tag?.slug;
  const RenderType = variant || 'div';

  if (genre) {
    return (
      <RenderType
        className={classnames(classes.workTypeTag, className, {
          [classes[`workTypeTag_type_${type}`]]: type,
          [classes.isBlur]: tag?.id === -1,
        })}
      >
        {genre}
      </RenderType>
    );
  }

  return <div />;
};

export default WorkTypeTag;
